<template>
  <li>
    <a
      :href="`/${baseUri}/${section.id}`"
      class="row"
      :class="{
        'practice-area': stream == 'practiceArea',
        corecontent: stream == 'corecontent',
      }"
    >
      <div class="titles col-xs-12 col-lg-7 col-no-gutter">
        <h3>{{ section.sectionName }}</h3>
        <p>{{ section.sectionDescription }}</p>
      </div>
      <div class="content col-xs-3 col-no-gutter">
        <div class="learning-module-count">
          {{ section.moduleCount }} Resources
        </div>
      </div>
      <div class="arrow col-xs-2"><arrow /></div>
    </a>
  </li>
</template>

<script>
import Arrow from "@/images/arrow.svg";
export default {
  components: {
    Arrow,
  },
  props: {
    section: Object,
    baseUri: {
      type: String,
    },
    stream: {
      type: String,
      required: true,
    },
  },
  computed: {
    // moduleCount() {},
  },
};
</script>

<style scoped lang="scss">
a {
  display: block;
  background-color: $colour-light-gray;
  color: $text-dark;
  padding: 1em;
  border-radius: $border-radius;
  margin-bottom: 1rem;

  @include breakpoint(md) {
    display: flex;
    padding: 2em;
  }

  &:hover,
  &:focus {
    background-color: darken($colour-light-gray, 7);
  }
}
h3 {
  margin: 0;
  margin-bottom: 0.75em;
}
p {
  margin: 0;
  margin-bottom: 0.75em;
  @include breakpoint(md) {
    margin-bottom: 0;
  }
}
li {
}
svg {
  // width: 100%;
}
.arrow {
  display: none;
  align-items: center;
  justify-content: flex-end;

  svg {
    max-width: 1.5em;
  }

  @include breakpoint(md) {
    display: flex;
  }
}

.content {
  font-size: 0.75em;
  display: flex;
  align-items: center;

  .learning-module-count {
    color: white;
    padding: 0.25em 0.5em;
    font-size: 0.9em;

    background: $colour-accent;
    border-radius: $border-radius;

    .corecontent & {
      background: $gradient-blue;
    }

    .practice-area & {
      background: $gradient-purple;
    }

    @include breakpoint(sm) {
      font-size: 1em;
    }

    @include breakpoint(md) {
      margin: 0 auto;
    }
  }
}
</style>