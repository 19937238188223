var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"resource-module",class:{
    'core-content': _vm.moduleType == 'core-content',
    'practice-area': _vm.moduleType == 'practice-area',
    'additional-resource': _vm.moduleType == 'additional-resource',
    skeleton: _vm.skeleton,
  },attrs:{"href":_vm.url,"target":"_blank"}},[_c('div',{staticClass:"image",style:({
      backgroundImage: _vm.image ? `url('${_vm.image}')` : false,
      backgroundColor: !_vm.image ? _vm.generateColour(_vm.title) : false,
    })}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),(_vm.source)?_c('h4',[_vm._v("By "+_vm._s(_vm.source))]):_vm._e(),_c('ul',{staticClass:"metadata"},[(_vm.time)?_c('li',{staticClass:"time"},[_c('clock'),_vm._v(" "+_vm._s(_vm.timeFormated))],1):_c('li'),_c('li',[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.date ? _vm.date.toLocaleString("default", { month: "short", year: "numeric", }) : _vm.moduleTypeString)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }