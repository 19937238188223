var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-modules"},[_c('glider',{attrs:{"options":{
      slidesToShowSM: 1,
      slidesToShowMD: 2,
      slidesToShowLG: 3,
      slidesToShow: 3,
      draggable: false,
    }}},_vm._l((_vm.learningModules),function(learningModule){return _c('div',{key:learningModule.slug,staticClass:"slide"},[_c('resource-module',{attrs:{"image":learningModule.moduleImage
            ? learningModule.moduleImage.url
            : undefined,"title":learningModule.title,"source":learningModule.source,"time":learningModule.length,"type":"module","date":learningModule.postDate
            ? new Date(learningModule.postDate.date)
            : undefined,"url":learningModule.moduleResource
            ? learningModule.moduleResource.url
            : undefined,"module-type":_vm.getModuleType(learningModule.typeHandle)}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }