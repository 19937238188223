<template>
  <div>
    <b-pagination
        v-model="currentPage"
        v-if="moduleCount > perPage"
        :total-rows="moduleCount"
        :per-page="perPage"
        @change="handlePageChange"
        first-text=""
        prev-text="Prev"
        next-text="Next"
        last-text=""
        next-class="next"
        prev-class="prev"
    ></b-pagination>

    <ol class="row">
      <li
          v-for="(policy, index) in policies"
          :key="index"
          class="col-xs-12 col-md-6"
      >
        <policy :title="policy.title" :url="policy.url" class="section-policy"/>
      </li>
    </ol>


  </div>
</template>

<script>
export default {
  props: {
    sectionId: {
      type: String,
      required: true,
    },
    stream: {
      type: String,
      required: true,
    },
    practiceArea: {
      type: String,
      required: false,
    },
    ssrData: {
      type: Array,
      required: false,
    },
    ssrMeta: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      moduleCount: this.ssrMeta ? this.ssrMeta.total : null,
      perPage: 8,
      currentPage: 1,
      policies: this.ssrData ? this.ssrData : [],
    };
  },
  async mounted() {
    const { data } = await this.getpolicies();

    this.moduleCount = data.meta.pagination.total;
    this.policies = data.data;
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },
    async getpolicies() {
      return await this.axios.get(`policies`, {
        params: {
          stream: this.$props.stream,
          practiceArea: this.$props.practiceArea,
          sectionID: this.$props.sectionId,
          itemsPerPage: this.perPage,
          page: this.currentPage,
        },
      });
    },
  },
  watch: {
    async currentPage() {
      const { data } = await this.getpolicies();

      this.policies = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-policy {
  height: 100%;
  box-sizing: border-box;
}
</style>