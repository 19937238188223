export default function (length) {
    const array = [];
    for (var i = 0; i < length; i++) {
        array.push({
            skeleton: true,
        });
    }

    return array;
    
}