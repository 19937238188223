<template>
  <a :href="url" target="_blank">
    {{ title }}
    <link-icon />
  </a>
</template>

<script>
import LinkIcon from "@/images/link.svg";
export default {
  components: {
    LinkIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
a {
  background-color: $colour-light-gray;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    background-color: darken($colour-light-gray, 7);
  }
}

svg {
  float: right;
  height: 1em;
  min-width: 1em;
  margin-left: 1em;
  align-self: center;
}
</style>