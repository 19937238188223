var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.moduleCount > _vm.perPage)?_c('b-pagination',{attrs:{"total-rows":_vm.moduleCount,"per-page":_vm.perPage,"first-text":"","prev-text":"Prev","next-text":"Next","last-text":"","next-class":"next","prev-class":"prev"},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('ol',{staticClass:"row"},_vm._l((_vm.resourceModulesCapped),function(resourceModule){return _c('li',{key:resourceModule.id,staticClass:"col-xs-12 col-md-6 col-lg-4"},[_c('resource-module',{attrs:{"image":resourceModule.moduleImage
            ? resourceModule.moduleImage.url
            : undefined,"title":resourceModule.title,"source":resourceModule.source,"time":resourceModule.length,"type":"module","date":resourceModule.postDate
            ? new Date(resourceModule.postDate.date)
            : new Date(),"url":resourceModule.moduleResource
            ? resourceModule.moduleResource.url
            : undefined,"module-type":"additional-resource","skeleton":resourceModule.skeleton}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }