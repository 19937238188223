<template>
  <section class="resources-section">
    <h3>Resources</h3>
    <div class="resources-grid">
      <a
          :href="resource.url"
          class="resource"
          target="_blank"
          v-for="resource in resources"
      >
        <document-icon/>
        <div class="text">
          <h4>{{ resource.title }}</h4>
          <div class="info">
            <span class="size">{{ resource.size }}</span> /
            <span class="ext">{{ resource.extension }}</span>
          </div>
        </div>
        <download-icon/>
      </a>
    </div>
  </section>
</template>

<script>
import DocumentIcon from "@/images/document.svg";
import DownloadIcon from "@/images/download.svg";

export default {
  components: {
    DocumentIcon,
    DownloadIcon,
  },
  props: {
    resources: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.resources-section {
  margin-top: 2rem;

  h3 {
    margin-bottom: 1rem;
  }
}

h4 {
  margin-bottom: 0.25em;
}

.text {
  flex: 1;
  margin-left: 1em;
}

.resources-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.resource {
  background: $colour-light-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  &:hover,
  &:focus {
    background-color: darken($colour-light-gray, 7);
  }
}

.info {
  font-size: 0.75em;
  color: $colour-dark-gray;
}

.ext {
  text-transform: uppercase;
}
</style>