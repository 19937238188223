<template>
  <div class="glider-contain">
    <div class="glider-wrap">
      <div class="glider">
        <slot />
      </div>
    </div>

    <button aria-label="Previous" class="glider-prev">‹</button>
    <button aria-label="Next" class="glider-next">›</button>
    <div role="tablist" class="dots"></div>
  </div>
</template>

<script>
import Glider from "glider-js";
export default {
  props: { options: Object },

  data() {
    return {
      glider: undefined,
    };
  },
  mounted() {
    document.addEventListener("glider-loaded", this.hideFFScrollBars);
    document.addEventListener("glider-refresh", this.hideFFScrollBars);

    const responsive = [];
    let slidesToShow = this.$props.options.slidesToShow;

    if (this.$props.options.slidesToShowSM) {
      slidesToShow = this.$props.options.slidesToShowSM;
      responsive.push({
        breakpoint: 600,
        settings: {
          slidesToShow: this.$props.options.slidesToShowMD,
        },
      });
      responsive.push({
        breakpoint: 800,
        settings: {
          slidesToShow: this.$props.options.slidesToShowLG,
        },
      });
    }

    this.glider = new Glider(this.$el.querySelector(".glider"), {
      slidesToShow: slidesToShow,
      slidesToScroll: "auto",
      draggable: this.$props.options.draggable,
      rewind: true,
      scrollLock: false,
      dots: ".dots",
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next",
      },
      responsive: responsive
    });
  },
  methods: {
    hideFFScrollBars(e) {
      if (/firefox/i.test(navigator.userAgent)) {
        if (window.innerWidth > 575 && e.target.id !== "glider-persp") {
          e.target.parentNode.style.height = e.target.offsetHeight - 17 + "px";
        }
      }
    },
  },
};
</script>

<style lang="scss">
// Styles for glider
@import "~glider-js/glider.css";

.glider-contain {
  .dots {
    margin-top: 1rem;
  }
}

.glider-dot {
  background: none;
  border: 1px solid $colour-white;

  &.active {
    background: $colour-white;
  }

  &:hover {
    background: rgba($colour-white, 0.5);
  }
}

.glider-next,
.glider-prev {
  color: $colour-white;

  &:hover,
  &:focus {
    color: rgba($colour-white, 0.5);
  }
}

@-moz-document url-prefix() {
  .glider-track {
    margin-bottom: 17px;
  }
  .glider-wrap {
    overflow: hidden;
  }
}
</style>