<template>
  <div class="featured-modules">
    <glider
        :options="{
        slidesToShowSM: 1,
        slidesToShowMD: 2,
        slidesToShowLG: 3,
        slidesToShow: 3,
        draggable: false,
      }"
    >
      <div
          v-for="learningModule in learningModules"
          :key="learningModule.slug"
          class="slide"
      >
        <!-- {{learningModule}} -->
        <resource-module
            :image="
            learningModule.moduleImage
              ? learningModule.moduleImage.url
              : undefined
          "
            :title="learningModule.title"
            :source="learningModule.source"
            :time="learningModule.length"
            type="module"
            :date="
            learningModule.postDate
              ? new Date(learningModule.postDate.date)
              : undefined
          "
            :url="
            learningModule.moduleResource
              ? learningModule.moduleResource.url
              : undefined
          "
            :module-type="getModuleType(learningModule.typeHandle)"
        />
      </div>
    </glider>
  </div>
</template>

<script>
export default {
  props: {
    learningModules: {
      type: Array,
    },
  },
  methods: {
    getModuleType(typeHandle) {
      if (typeHandle == 'learningModulePA') return 'practice-area'
      if (typeHandle == 'learningModuleCorecontent') return 'core-content'
      if (typeHandle == 'additionalResource') return 'additional-resource'
      return ''
    }
  }
};
</script>

<style lang="scss" scoped>
.featured-modules {
  margin: 0 2em;
}

.slide {
  padding: 0 1em;
  padding-top: 0.4em; //Leave room for hover float effect on learning modules
}
</style>