<template>
  <div>
    <b-pagination
        v-model="currentPage"
        v-if="moduleCount > perPage"
        :total-rows="moduleCount"
        :per-page="perPage"
        @change="handlePageChange"
        first-text=""
        prev-text="Prev"
        next-text="Next"
        last-text=""
        next-class="next"
        prev-class="prev"
    ></b-pagination>

    <ol class="row">
      <li
          v-for="resourceModule in resourceModulesCapped"
          :key="resourceModule.id"
          class="col-xs-12 col-md-6 col-lg-4"
      >
        <resource-module
            :image="
            resourceModule.moduleImage
              ? resourceModule.moduleImage.url
              : undefined
          "
            :title="resourceModule.title"
            :source="resourceModule.source"
            :time="resourceModule.length"
            type="module"
            :date="
            resourceModule.postDate
              ? new Date(resourceModule.postDate.date)
              : new Date()
          "
            :url="
            resourceModule.moduleResource
              ? resourceModule.moduleResource.url
              : undefined
          "
            module-type="additional-resource"
            :skeleton="resourceModule.skeleton"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import generateSkeletonArray from "@/helpers/generateSkeletonArray";

export default {
  props: {
    sectionId: {
      type: String,
      required: true,
    },
    stream: {
      type: String,
      required: true,
    },
    practiceArea: {
      type: String,
      required: false,
    },
    ssrData: {
      type: Array,
      required: false,
    },
    ssrMeta: {
      type: Object,
      required: false,
    },
    orderBy: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      moduleCount: this.ssrMeta ? this.ssrMeta.total : null,
      currentPage: 1,
      resourceModules: this.ssrData ? this.ssrData : generateSkeletonArray(6),
    };
  },
  async mounted() {
    const { data } = await this.getresourceModules();

    this.moduleCount = data.meta.pagination.total;
    this.resourceModules = data.data;
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },
    async getresourceModules() {
      return await this.axios.get(`additional-resources`, {
        params: {
          stream: this.$props.stream,
          practiceArea: this.$props.practiceArea,
          sectionID: this.$props.sectionId,
          itemsPerPage: this.perPage,
          page: this.currentPage,
          orderBy: this.orderBy,
        },
      });
    },
    async updateResourceModules() {
      const { data } = await this.getresourceModules();

      this.resourceModules = data.data;
    },
  },
  computed: {
    perPage() {
      switch (this.$mq) {
        case "xs":
        case "sm":
          return 2;
        case "md":
          return 4;
        default:
          return 6;
      }
    },
    resourceModulesCapped() {
      return this.resourceModules.slice(0, this.perPage);
    },
    mq() {
      return this.$mq;
    },
  },
  watch: {
    mq() {
      this.updateResourceModules();
    },
    async currentPage() {
      this.updateResourceModules();
    },
    orderBy() {
      this.updateResourceModules();
    },
  },
};
</script>

<style>
</style>