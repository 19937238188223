<template>
  <div>
    <b-pagination
        v-model="currentPage"
        v-if="moduleCount > perPage"
        :total-rows="moduleCount"
        :per-page="perPage"
        @change="handlePageChange"
        first-text=""
        prev-text="Prev"
        next-text="Next"
        last-text=""
        next-class="next"
        prev-class="prev"
    ></b-pagination>

    <ol class="row">
      <li
          v-for="learningModule in learningModulesCapped"
          :key="learningModule.id"
          class="col-xs-12 col-md-6 col-lg-4"
      >
        <resource-module
            :image="
            learningModule.moduleImage
              ? learningModule.moduleImage.url
              : undefined
          "
            :title="learningModule.title"
            :source="learningModule.source"
            :time="learningModule.length"
            type="module"
            :date="
            learningModule.postDate
              ? new Date(learningModule.postDate.date)
              : new Date()
          "
            :url="
            learningModule.moduleResource
              ? learningModule.moduleResource.url
              : undefined
          "
            :module-type="
            stream == 'core-content' ? 'core-content' : 'practice-area'
          "
            :skeleton="learningModule.skeleton"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import generateSkeletonArray from "@/helpers/generateSkeletonArray";

export default {
  props: {
    sectionId: {
      type: String,
      required: true,
    },
    stream: {
      type: String,
      required: true,
    },
    practiceArea: {
      type: String,
      required: false,
    },
    ssrData: {
      type: Array,
      required: false,
    },
    ssrMeta: {
      type: Object,
      required: false,
    },
    orderBy: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      moduleCount: this.ssrMeta ? this.ssrMeta.total : null,
      // perPage: 6,
      currentPage: 1,
      learningModules: this.ssrData ? this.ssrData : generateSkeletonArray(6),
    };
  },
  async mounted() {
    const { data } = await this.getLearningModules();

    this.moduleCount = data.meta.pagination.total;
    this.learningModules = data.data;
  },
  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },
    async getLearningModules() {
      return await this.axios.get(`learning-modules`, {
        params: {
          stream: this.$props.stream,
          practiceArea: this.$props.practiceArea,
          sectionID: this.$props.sectionId,
          itemsPerPage: this.perPage,
          page: this.currentPage,
          orderBy: this.orderBy,
        },
      });
    },
    async updateLeraningModules() {
      const { data } = await this.getLearningModules();

      this.learningModules = data.data;
    },
  },
  computed: {
    perPage() {
      switch (this.$mq) {
        case "xs":
        case "sm":
          return 2;
        case "md":
          return 4;
        default:
          return 6;
      }
    },
    learningModulesCapped() {
      return this.learningModules.slice(0, this.perPage);
    },
    mq() {
      return this.$mq;
    },
  },
  watch: {
    mq() {
      this.updateLeraningModules();
    },
    async currentPage() {
      this.updateLeraningModules();
    },
    orderBy() {
      this.updateLeraningModules();
    },
  },
};
</script>

<style>
</style>