export default function (Vue) {
    document.getElementById("mhsu-search") ? new Vue({
        el: "#mhsu-search",
        delimiters: ["${", "}"],
        data: () => ({
            initialLoad: false,
            searching: false,
            searchInput: '',
            searchType: 'combo-search',
            searchResults: {
                type: '',
                data: []
            },
            perPage: 9,
            currentPage: 1,
            resultCount: 0,
            filteredPracticeArea: '',
            filteredSection: undefined,
            sections: [],
            onInputChangeDebounced: undefined
        }),
        methods: {
            handlePageChange(value) {
                this.currentPage = value;
                this.getSearchResults();
            },
            onInputChange(event) {
                this.searching = true;
                this.onInputChangeDebounced(event);
            },
            async getSearchResults() {
                this.searching = true;

                if (this.searchInput) {
                    const { data } = await this.axios.get(this.searchType, {
                        params: {
                            search: this.searchInput,
                            stream: this.stream,
                            practiceArea: this.filteredPracticeArea,
                            sectionID: this.filteredSection,
                            itemsPerPage: this.perPage,
                            page: this.currentPage,
                        },
                    });

                    this.resultCount = data.meta.pagination.total;

                    this.searchResults = {
                        type: this.searchType,
                        data: data.data
                    };
                }
                // Don't return any results with search input is blank
                else {
                    this.searchResults = {
                        type: this.searchType,
                        data: []
                    };
                }
                this.searching = false;
            },
            readURLParams() {
                // Construct URLSearchParams object instance from current URL querystring.
                var queryParams = new URLSearchParams(window.location.search);

                // Get parameter values and update state 
                if (queryParams.get('t')) this.searchType = queryParams.get('t');
                if (queryParams.get('pa')) this.filteredPracticeArea = queryParams.get('pa');
                if (queryParams.get('s')) this.filteredSection = queryParams.get('s');
                if (queryParams.get('q')) this.searchInput = queryParams.get('q');

            },
            writeURLParams() {
                // Construct URLSearchParams object instance from current URL querystring.
                var queryParams = new URLSearchParams(window.location.search);

                // Set new or modify existing parameter value. 
                queryParams.set("q", this.searchInput);
                queryParams.set("t", this.searchType);
                queryParams.set("pa", this.filteredPracticeArea);
                queryParams.set("s", this.filteredSection);

                // Replace current querystring with the new one.
                history.replaceState(null, null, "?" + queryParams.toString());
            }
        },
        watch: {
            searchType() {
                if (!this.initialLoad) return;

                this.getSearchResults();
                this.writeURLParams();
            },
            async filteredPracticeArea() {
                const initialLoad = this.initialLoad;
                if (this.filteredPracticeArea == 'core-content') {
                    const { data } = await this.axios.get(`core-content`);
                    this.sections = data.sections;
                    if (initialLoad) {
                        this.filteredSection = this.sections[0].id;
                    }

                }
                else if (this.filteredPracticeArea) {
                    const { data } = await this.axios.get(`practice-areas/${this.filteredPracticeArea}`);
                    this.sections = data.sections;

                    if (initialLoad) {
                        this.filteredSection = this.sections[0].id;
                    }
                }
                else {
                    this.sections = [];
                    this.filteredSection = undefined;
                }

            },
            filteredSection() {
                if (!this.initialLoad) return;

                this.writeURLParams();
                this.getSearchResults();
            }
        },
        computed: {
            resultColumnClass() {
                if (this.searchResults.type == 'learning-modules' || this.searchResults.type == 'additional-resources') {
                    return 'col-xs-12 col-md-6 col-lg-4'
                }
                else if (this.searchResults.type == 'policies') {
                    return 'col-sm-12 col-md-6'
                }
                else if (this.searchResults.type == 'combo-search') {
                    return 'col-xs-12 col-md-6 col-xl-4'
                }
            },
            stream() {
                if (this.filteredPracticeArea == 'core-content') {
                    return 'core-content';
                }
                else if (this.filteredPracticeArea) {
                    return 'practice-areas';
                }
                else return undefined;
            },
            message() {
                if (!this.initialLoad) return "Loading"
                else if (this.searching) return "Searching"
                else return "No Results Found"
            }
        },
        mounted() {
            const debounce = require('lodash.debounce');

            // Debounce search input to prevent unnecessary API hits to the server
            this.onInputChangeDebounced = debounce(() => {
                this.getSearchResults();
                if (this.initialLoad) this.writeURLParams();
            }, 200); // 200 ms

            this.readURLParams();
            this.getSearchResults();

            this.$nextTick(() => this.initialLoad = true)

            this.$refs.search.focus();
        }
        // components: {},
    }) : false;
}