<template>
  <form ref="newsletterForm" :class="{ invalid: invalid }">
    <fieldset>
      <slot></slot>
      <div class="field">
        <input
          type="name"
          name="fromName"
          aria-label="Full Name"
          id="from-name"
          placeholder="Full Name"
          required
        />
      </div>
      <div class="field">
        <input
          type="email"
          aria-label="VCH Email"
          name="fromEmail"
          id="from-email"
          placeholder="VCH Email"
          pattern="[\w.%+-]+@vch\.ca"
          title="Only VCH emails may sign up to the newsletter"
          required
        />
      </div>

      <div class="">
        <input
          class=""
          type="submit"
          @click="onSubmit"
          :value="submitButtonText"
          :disabled="submitted"
        />
      </div>
    </fieldset>
  </form>
</template>

<script>
export default {
  data: () => ({
    errors: [],
    submitted: false,
    submitting: false,
    invalid: false,
  }),
  methods: {
    onSubmit(event) {
      this.submitting = true;
      event.preventDefault();
      this.$refs.newsletterForm.checkValidity();

      // console.log(this.$refs.newsletterForm);
      if (!this.$refs.newsletterForm.reportValidity()) {
        this.submitting = false;
        this.invalid = true;
        return false;
      } else {
        this.invalid = false;
      }

      const formData = new FormData(this.$refs.newsletterForm);
      formData.append("action", "contact-form/send");
      formData.append("message", "Request to join newsletter");
      this.axios({
        method: "post",
        url: "/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(({ data }) => {
          //handle success
          console.log(data);
          if (data.errors) {
            this.errors = data.errors;
            this.submitted = false;
            this.submitting = false;
            this.invalid = true;
            alert(data.errors[0]);
          } else {
            this.submitted = true;
            this.submitting = false;
            this.invalid = false;
          }
        })
        .catch((response) => {
          //handle error
          console.error(response);
          this.submitting = false;
          this.invalid = true;
        });
    },
  },
  computed: {
    submitButtonText() {
      if (this.submitting) return "Submitting...";
      else if (this.submitted) return "Success!";
      else return "Submit";
    },
  },
};
</script>

<style scoped lang="scss">
[type="name"],
[type="email"] {
  background: none;
  border: none;
  padding: 0;
  border-bottom: solid 1px $colour-medium-gray;
  color: $colour-medium-gray;

  // .invalid & {
  //   :invalid {
  //     border-bottom-color: red;
  //   }
  // }
}
.field {
  margin-bottom: 1rem;
}
[type="submit"] {
  background: none;
  border: 1px solid $colour-medium-gray;
  border-radius: $border-radius;
  color: $colour-medium-gray;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.5em 1em;
}
</style>