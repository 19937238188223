<template>
  <ul
    class="practice-areas"
    v-if="$mq !== 'xs' && $mq !== 'sm' && $mq !== 'md'"
  >
    <li v-for="area in $props.practiceAreas" :key="area.title">
      <button
        @click="$emit('click', area.slug)"
        :class="{
          active: area.slug == $props.activePracticeArea,
          loading: area.slug == $props.loadingPracticeArea,
        }"
      >
        {{ area.title }}
      </button>
    </li>
  </ul>
  <div class="select" v-else>
    <select @change="$emit('click', $event.target.value)">
      <option
        v-for="area in $props.practiceAreas"
        :key="area.title"
        :value="area.slug"
      >
        {{ area.title }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    practiceAreas: {
      type: Array,
      required: true,
    },
    activePracticeArea: {
      type: String,
      required: true,
    },
    loadingPracticeArea: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes border-pulsate {
  0% {
    border-color: lighten($gradient-purple-start, 25%);
  }
  50% {
    border-color: lighten($gradient-purple-end, 25%);
  }
  100% {
    border-color: lighten($gradient-purple-start, 25%);
  }
}

.practice-areas {
  display: flex;

  li {
    flex: 1 1 0;
  }

  button {
    cursor: pointer;
    width: 100%;
    padding-bottom: 1em;
    border: none;
    border-width: 0;
    border-bottom: 5px solid $colour-medium-gray;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7em;

    @include breakpoint(lg) {
      font-size: 0.85em;
    }

    &.active {
      // border-bottom: 10px solid;
      border-image-slice: 1;
      border-image-source: $gradient-purple;
    }
    &.loading {
      animation: border-pulsate 1s infinite;
    }
  }
}
</style>