export default function (Vue) {
    document.getElementById("newsletter") ? new Vue({
        el: "#newsletter",
        delimiters: ["${", "}"],
        data: () => ({

        }),
        methods: {

        },
        mounted() {

        }
        // components: {},
    }) : false;
}