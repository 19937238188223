<template>
  <a
    class="resource-module"
    :href="url"
    target="_blank"
    :class="{
      'core-content': moduleType == 'core-content',
      'practice-area': moduleType == 'practice-area',
      'additional-resource': moduleType == 'additional-resource',
      skeleton: skeleton,
    }"
  >
    <div
      class="image"
      :style="{
        backgroundImage: image ? `url('${image}')` : false,
        backgroundColor: !image ? generateColour(title) : false,
      }"
    ></div>
    <div class="info">
      <h3>{{ title }}</h3>
      <h4 v-if="source">By {{ source }}</h4>
      <ul class="metadata">
        <li class="time" v-if="time"><clock /> {{ timeFormated }}</li>
        <li v-else></li>
        <li>
          <div class="date">
            {{
              date
                ? date.toLocaleString("default", {
                    month: "short",
                    year: "numeric",
                  })
                : moduleTypeString
            }}
          </div>
        </li>
      </ul>
    </div>
  </a>
</template>

<script>
import Clock from "@/images/clock.svg";
import ColorHash from "color-hash/dist/esm.js";
export default {
  components: {
    Clock,
  },
  props: {
    image: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "Title",
    },
    source: {
      type: String,
      required: false,
      default: "Unknown",
    },
    time: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    moduleType: {
      type: String,
      required: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    generateColour(hash) {
      const colorHash = new ColorHash({ lightness: 0.6 });
      return colorHash.hex(hash);
    },
  },
  computed: {
    // Format time string in a human readable format depending on how many minutes long it is
    timeFormated() {
      if (this.$props.time < 60) {
        return `${this.$props.time} mins`; // Display in minutes
      } else {
        return `${Math.round((this.$props.time / 60) * 10) / 10} hours`; //Convert to hours and round to 1 decimal
      }
    },
    moduleTypeString() {
      if (this.moduleType == "core-content") return "Core";
      else if (this.moduleType == "practice-area") return "PA";
      else if (this.moduleType == "additional-resource")
        return "Additional Resource";
      else "";
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-module {
  display: flex;
  flex-direction: column;
  background-color: $colour-white;
  border-radius: $border-radius;
  box-shadow: 0px 25px 25px rgba(152, 152, 152, 0.14);
  overflow: hidden;
  height: 100%;
  transition: transform 150ms;

  &:hover,
  &:focus {
    transform: translateY(-0.4em);
  }
}
.info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
h3 {
  font-size: 0.9em;

  .skeleton & {
    background-color: $colour-light-gray;
    width: 100%;
    color: $colour-light-gray;
  }
}
h4 {
  margin-top: 0.4em;
  font-weight: normal;
  font-size: 0.8em;

  .skeleton & {
    background-color: $colour-light-gray;
    width: 40%;
    color: $colour-light-gray;
  }
}
.metadata {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin: 1em 0 0 0;
  margin-top: auto;
  padding-top: 1em;

  li {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1em;
    margin-right: 0.5em;

    .practice-area & {
      stroke: $gradient-purple-start;
    }
    .core-content & {
      stroke: $gradient-blue-start;
    }
  }
}
.time {
  text-transform: uppercase;
  // font-weight: bold;
  font-size: 0.8em;
}
.date {
  background: black;
  border-radius: $border-radius;
  color: $text-light;
  font-size: 0.75em;
  padding: 0.25em 0.5em;

  .skeleton & {
    color: rgba(255, 255, 255, 0);
  }

  .practice-area & {
    background: $gradient-purple;
  }
  .core-content & {
    background: $gradient-blue;
  }
  .additional-resource & {
    background-color: $colour-accent;
  }
}

.image {
  //   height: 5em;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }

  .skeleton & {
    background-color: $colour-light-gray;
    min-width: 100%;
    min-height: 1em;
  }
}
</style>