export default function (Vue) {
    document.getElementById("mhsu-section") ? new Vue({
        el: "#mhsu-section",
        delimiters: ["${", "}"],
        data: () => ({
            searchQuery: '',
            practiceArea: '',
            sectionId: '',
            learningModuleOrder: 'postDate asc',
            resourceModuleOrder: 'postDate asc'
        }),
        methods: {
            search() {
                const params = new URLSearchParams()
                params.append('q', this.searchQuery);
                params.append('pa', this.practiceArea);
                params.append('s', this.sectionId);

                window.location.href = '/search?' + params.toString();
            }
        },
        mounted(){
            this.sectionId = this.$el.dataset.section;
            this.practiceArea = this.$el.dataset.practiceArea;
            console.log(this.practiceArea)
        }
        // components: {},
    }) : false;
}