import Vue from "vue";

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMq from 'vue-mq'

import { PaginationPlugin } from 'bootstrap-vue'

import bulmaCollapsible from '@creativebulma/bulma-collapsible';

import search from './search';
import section from './section';
import newsletter from './newsletter';

import nav from './nav';
import dropdown from './dropdown';

import Components from "./components";
import "@/scss/_base.scss";

bulmaCollapsible.attach();

Vue.config.productionTip = false;

const axoisInstance = axios.create({
  baseURL: '/api/',
});

Vue.use(VueAxios, axoisInstance)
Vue.use(PaginationPlugin)
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 300,
    sm: 600,
    md: 800,
    lg: 1000,
    xl: 1200,
  },
  defaultBreakpoint: 'md' // customize this for SSR
})

document.getElementById("app") ? new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  // components: {},
}) : false;

search(Vue);
section(Vue);
newsletter(Vue);

nav();
dropdown();