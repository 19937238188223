<template>
  <div class="select is-small">
    <select name="section" @change="update" aria-label="Order Dropdown">
      <option value="postDate asc">Most Recent</option>
      <option value="length asc">Short First</option>
      <option value="length desc">Long First</option>
      <option value="title asc">A - Z</option>
      <option value="title desc">Z - A</option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style>
</style>