export default function () {
    document.addEventListener('DOMContentLoaded', () => {

        // Hamburger Menu

        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {

            // Add a click event on each of them
            $navbarBurgers.forEach(el => {
                el.addEventListener('click', () => {

                    // Get the target from the "data-target" attribute
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);

                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    el.classList.toggle('is-active');
                    $target.classList.toggle('is-active');

                });
            });
        }

        // Trigger Newseltter Colour Flash
        const newsletterElement = document.querySelector('#newsletter');
        const newsletterButton = document.querySelector('#newsletter-button');

        const mainNavElement = document.querySelector('#main-nav');
        const mainBurgerElement = document.querySelector('[data-target=main-nav]');

        newsletterButton.addEventListener('click', () => {
            newsletterElement.classList.remove("flash-size");
            void newsletterElement.offsetWidth;
            newsletterElement.classList.add("flash-size");

            mainNavElement.classList.remove("is-active"); // Hide Mobile Menu if it's open
            mainBurgerElement.classList.remove("is-active");

        })


    });
}