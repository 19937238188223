<template>
  <div>
    <!-- Practice Areas -->
    <practice-areas
        :practice-areas="$props.practiceAreas"
        :loading-practice-area="loadingPracticeArea"
        :active-practice-area="currentPracticeArea.slug"
        v-if="!corecontent"
        @click="onClickPracticeArea"
    />
    <!-- Sections -->
    <ul v-if="practiceArea">
      <section-item
          v-for="section in currentPracticeArea.sections"
          :stream="corecontent ? 'corecontent' : 'practiceArea'"
          :section="section"
          :key="section.id"
          :base-uri="`${$props.baseUri}${
          !corecontent ? '/' + currentPracticeArea.slug : ''
        }`"
      />
    </ul>

    <!-- Resources -->
    <stream-resources v-if="currentPracticeArea && currentPracticeArea.streamEntryResources"
                      :resources="currentPracticeArea.streamEntryResources"/>
  </div>
</template>

<script>
export default {
  props: {
    practiceAreas: {
      type: Array,
    },
    baseUri: {
      type: String,
    },
    corecontent: {
      type: Boolean,
      default: false,
    },
    practiceArea: {
      type: Object,
    },
  },
  data() {
    return {
      currentPracticeArea: this.$props.practiceArea
          ? this.$props.practiceArea
          : undefined,
      loadingPracticeArea: undefined,
    };
  },
  async mounted() {
    // Whenn browser modifies state with navigation and slug changes, update practice area
    window.addEventListener("popstate", (event) => {
      const slug = document.location.pathname.substring(
          document.location.pathname.lastIndexOf("/") + 1
      );
      if (slug && slug != this.$props.baseUri) this.updatePracticeArea(slug);
    });
  },
  methods: {
    async onClickPracticeArea(slug) {
      this.updatePracticeArea(slug);
      history.pushState({}, null, "/" + this.$props.baseUri + "/" + slug);
    },
    async updatePracticeArea(slug) {
      this.loadingPracticeArea = slug;
      const practiceArea = await this.axios.get(`practice-areas/${ slug }`);

      this.currentPracticeArea = practiceArea.data;
      this.loadingPracticeArea = undefined;
    },
  },
};
</script>